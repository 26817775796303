import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stripePublishableKey", "cardInput", "cardTokenInput", "cardError"];

  connect() {
    const form = this.element.closest("form");

    //
    // Fetch the stripe publishable key from the HTML element
    //
    const publishableKey = this.stripePublishableKeyTarget.dataset.stripePublishableKey;

    const stripe = window.Stripe(publishableKey);
    const elements = stripe.elements();

    const clearCardError = () => this.cardErrorTarget.classList.add("hidden");

    //
    // Initialize the card number element
    //
    const cardElement = elements.create("card", {});
    cardElement.mount(this.cardInputTarget);
    cardElement.on("change", clearCardError);

    //
    // Add an on-submit listener for the form
    //
    form.addEventListener("submit", async (e) => {
      if (e.submitter.name === "pay_by_check") return;
      e.preventDefault();

      const submitTarget = form.querySelector("[type=submit]");
      submitTarget.setAttribute("disabled", "");

      //
      // Attempt to tokenize the card number
      //
      const result = await stripe.createToken(cardElement);

      if (result.error) {
        //
        // On error, display the error message.
        //
        this.cardErrorTarget.classList.remove("hidden");
        this.cardErrorTarget.textContent = result.error.message;
        submitTarget.innerText = "Pay and Sign up";
        submitTarget.removeAttribute("disabled");
      } else {
        //
        // On success, add the token to the hidden field and submit to the server
        //
        this.cardTokenInputTarget.value = result.token.id;
        form.submit();
      }
    });
  }
}
