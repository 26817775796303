import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

const i = (key) => {
  const TEXT = {
    title: "Are you sure?",
    text: "This action is irreversible.",
    cancel: "Cancel",
    confirm: "Ok",
  };
  return TEXT[key];
};

export default class extends Controller {
  static targets = ["button"];
  static values = {
    confirmButtonColor: {
      type: String,
      default: "#3b82f6",
    },
  };

  connect() {
    this.confirmed = false;
    this.buttonTarget.dataset.action = "confirm-button#click";
  }

  click(event) {
    if (this.confirmed !== false) return;

    event.preventDefault();
    const that = this;
    Swal.fire({
      title: i("title"),
      text: i("text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: this.confirmButtonColorValue,
      reverseButtons: true,
      buttons: {
        cancel: i("cancel"),
        confirm: i("confirm"),
      },
    }).then((result) => {
      if (!result.isConfirmed) return;

      that.confirmed = true;
      that.buttonTarget.click();
    });
  }
}
